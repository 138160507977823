<template>
  <div>
    <trac-tab-window :sectionTitle="'Support'" :subLinks = "subLinks" />
  </div>
</template>

<script>
  import MixinForScrollToTop from '../offline-module/mixins/TabbedMixin'
  export default {
    mixins: [MixinForScrollToTop],
    data() {
    return {
      subLinks: [
      {
          name: "Support Form",
          path: "/support/support-form",
          selected: false,
          routerWindowClassString: 'bg-white mt-12 p-12 big-shadow',
          dataAttribute: ""
        },
        {
          name: "User Guides",
          path: "/support/user-guides",
          selected: false,
          routerWindowClassString: 'bg-white mt-12 p-12 big-shadow',
          dataAttribute: "user-guide-button"
        },
      ],
    };
  },
  }
</script>

<style lang="scss" scoped>

</style>